/** 自動生成されたファイルです。編集しないでください! */
"use strict";

module.exports = {
  aws_appsync_apiKey: "da2-ykght2nlqvdkpphiogecuxisay",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint:
    "https://pefhdwc34zemplfbg32adctfxu.appsync-api.ap-northeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-northeast-1",
  aws_cloud_logic_custom: [],
  aws_cognito_identity_pool_id:
    "ap-northeast-1:3ffb6901-145c-47b7-9f0c-3667869bc4a1",
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
    ],
  },
  aws_cognito_region: "ap-northeast-1",
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_mobile_analytics_app_id: "f584ed41ecc04579b588e9146da5dd08",
  aws_mobile_analytics_app_region: "ap-northeast-1",
  aws_project_region: "ap-northeast-1",
  aws_user_files_s3_bucket: "sereca-auth-test-file-storage-cb87c554",
  aws_user_files_s3_bucket_region: "ap-northeast-1",
  aws_user_pools_id: "ap-northeast-1_yhhzhvSJ8",
  aws_user_pools_web_client_id: "7fckplde03c720bqpaoglpqgb",
  oauth: {},
  serecaAnalyticsApiEndpoint:
    "https://nlk9rmj243.execute-api.ap-northeast-1.amazonaws.com",
  serecaCameraControlApiEndpoint:
    "https://vwpm8ju8b0.execute-api.ap-northeast-1.amazonaws.com",
  serecaUserManagementApiEndpoint:
    "https://7azi6g09r0.execute-api.ap-northeast-1.amazonaws.com",
};
