import { Auth } from "@aws-amplify/auth";

export namespace HttpApi {
    /**
     * HTTP 要求オプション
     */
    export interface RequestOptions extends RequestInit {
        /** URL クエリパラメーター */
        readonly parameters?: URLSearchParams;
    }
}

/**
 * HTTP API の共通処理を提供します。
 */
export class HttpApi {
    readonly #endpoint: string;

    /**
     * 新しい {@link HttpApi} インスタンスを初期化します。
     *
     * @param endpoint API エンドポイント
     */
    constructor(endpoint: string) {
        this.#endpoint = endpoint;
    }

    /**
     * HTTP 要求を送出します。
     *
     * `Authorization` ヘッダは自動的にログイン中 Cognito ユーザーのアクセストー
     * クンが設定されます。このとき、アクセストークンが古くなっていた場合はリフ
     * レッシュします。
     *
     * @param pathname パス名
     * @param options オプション
     * @returns HTTP 応答
     */
    protected async request(
        pathname: string,
        options: HttpApi.RequestOptions = {},
    ): Promise<Response> {
        // URL を作成する
        const url = new URL(pathname, this.#endpoint);
        const { parameters, ...requestInit } = options;
        url.search = parameters?.toString() ?? "";

        // 認証情報を設定する
        const headers = new Headers(requestInit.headers);
        const session = await Auth.currentSession(); // ← 必要ならリフレッシュされる
        requestInit.signal?.throwIfAborted();
        const token = session.getIdToken().getJwtToken();
        headers.set("Authorization", `Bearer ${token}`);

        // HTTP 要求を出す
        return fetch(url, { cache: "no-store", ...requestInit, headers });
    }
}
